import React from "react"
import { Link, graphql } from "gatsby"
import get from "lodash.get"

import getExcerptOfBody from "../utils/getExcerpt"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Grid from "../components/grid"
import Breadcrumbs from "../components/breadcrumbs"
import SubNav from "../components/sub-nav"
import Slices from "../components/slices"

export const query = graphql`
  query SubPageBySlug($uid: String!) {
    prismicSubPage(uid: { eq: $uid }) {
      id
      uid
      type
      data {
        name
        body {
          ... on PrismicSubPageDataBodyText {
            id
            slice_type
            slice_label
            primary {
              text {
                text
                richText
              }
            }
          }
          ... on PrismicSubPageDataBodyImage {
            id
            slice_type
            slice_label
            primary {
              caption {
                text
                richText
              }
              image {
                alt
                copyright
                gatsbyImageData(layout: CONSTRAINED, width: 856)
              }
            }
          }
        }
      }
    }
  }
`

export default function SubPage({ location, data, pageContext }) {
  const page = get(data, `prismicSubPage.data`)
  const parent = get(pageContext, `parent`)

  if (!page) return null

  return (
    <Layout location={location}>
      <SEO title={page.name} description={getExcerptOfBody(page.body)} />
      <Grid page>
        <Breadcrumbs>
          <Link to="/">Home</Link>
          <Link to={`/${parent.uid}`}>{parent.data.name}</Link>
          <span>{page.name}</span>
        </Breadcrumbs>
        <SubNav path={`/${parent.uid}`} pages={parent.data.sub_pages} />
        <Slices slices={page.body} page />
      </Grid>
    </Layout>
  )
}
