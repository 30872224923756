import styled from "styled-components"
import {
  compose,
  space,
  color,
  variant,
  layout,
  position,
  flexbox,
} from "styled-system"
import { Link } from "gatsby"
import Text from "./text"

export const Container = styled.div`
  ${position}
  ${layout}
  ${flexbox}
  ${space}
  top: 8.4rem;
  grid-column: subnav-start / subnav-end;
  flex-flow: column;
  align-self: flex-start;
  grid-row: span 3;
`

Container.defaultProps = {
  position: ["static", "static", "sticky"],
  display: ["none", "none", "flex"],
  mt: ["y.mobile.0.0", "y.desktop.2.0"],
  mb: ["y.mobile.0.0", "y.desktop.1.0"],
}

export const Nav = styled.nav``

export const NavLink = styled(Text)`
  display: block;

  &:hover {
    opacity: 0.5;
  }
  &.active {
    color: ${(props) => props.theme.colors.black[5]};
    ${variant({
      variants: {
        light: {
          borderColor: "#000",
        },
        dark: {
          borderColor: "#fff",
        },
      },
    })};
  }
`

NavLink.defaultProps = {
  as: Link,
  activeClassName: "active",
  fontFamily: "sansSerif",
  fontSize: "1.6rem",
  color: "black.0",
  my: ["y.mobile.0.5", "y.desktop.0.5"],
  variant: "light",
}
