import styled from "styled-components"
import { compose, space, layout, position, color, grid } from "styled-system"

export const Container = styled.nav(
  compose(space, color, layout, position, grid),
  {
    gridColumn: "breadcrumbs-start / breadcrumbs-end",

    zIndex: 10,
  }
)

Container.defaultProps = {
  position: ["sticky", "sticky", "static"],
  bg: "primary",
  top: ["y.mobile.0.0", "y.desktop.0.0"],
  py: ["y.mobile.1.0", "y.desktop.1.0"],
  display: ["grid", "grid", "block"],
  gridTemplateColumns:
    "auto [breadcrumbs-content-start] 1fr [breadcrumbs-content-end] auto",
  gridColumnGap: ["1.6rem", "2.4rem", "2.4rem"],
}

export const List = styled.ol`
  grid-column: breadcrumbs-content-start / breadcrumbs-content-end;
  display: flex;
  flex-flow: row;
  overflow: hidden;

  li:last-child {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`
