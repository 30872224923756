import React from "react"
import { Container, Nav, NavLink } from "./sub-nav.styles"
import Text from "./text"

export default function SubNav({ path, pages }) {
  return (
    <Container>
      <Text.Small fontWeight={500} color="text">
        Sub Menu
      </Text.Small>
      <Nav>
        {pages.map(({ sub_page }) => {
          const { uid, data } = sub_page.document
          return (
            <NavLink key={`sub-nav-${uid}`} to={`${path}/${uid}`}>
              {data.name}
            </NavLink>
          )
        })}
      </Nav>
    </Container>
  )
}
