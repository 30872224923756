import React from "react"
import { Container, List } from "./breadcrumbs.styles"
import Text from "./text"

export default ({ children }) => (
  <Container>
    <List>
      {children.map((link, index) => {
        const last = children.length - 1 === index
        return (
          <Text.Small
            key={`breadcrumbs-${index}`}
            as="li"
            color={last ? `black.6` : `black.2`}
          >
            {link}
            {!last && <span>&nbsp;/&nbsp;</span>}
          </Text.Small>
        )
      })}
    </List>
  </Container>
)
